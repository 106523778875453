@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i');

$sidebar-bg-color: linear-gradient(180deg, rgba(37, 50, 109, 1) 0%, rgba(0, 41, 117, 1) 50%, rgba(0, 41, 117, 1) 50%);
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: linear-gradient(180deg, rgba(37, 50, 109, 1) 0%, rgba(0, 41, 117, 1) 50%, rgba(0, 41, 117, 1) 50%);
$sidebar-color: #ffffff;
$sidebar-width: 250px;
$highlight-color: #f0f0f0;
$icon-bg-color: rgb(0, 65, 255);
$icon-size: 35px;

// @import '~react-pro-sidebar/dist/scss/styles.scss';

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    // padding-bottom: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;
      // border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    >span {
      margin-left: 10px;
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;

    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  // @media (max-width: $breakpoint-xs) {
  //   .btn-toggle {
  //     display: flex;
  //   }
  // }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar {
  .pro-menu {
    .pro-menu-item {
      >.pro-inner-item {
        &:hover {
          .pro-icon-wrapper {
            .pro-icon {
              animation: none;
            }
          }
        }
      }
    }
  }
}

.pro-sidebar .pro-menu .pro-menu-item {
  transition: 300ms;
}

.pro-sidebar .pro-menu .pro-menu-item:hover {
  transition: 300ms;
  background-color: rgb(0, 51, 146);
}

/*** VARIABLES ***/
/* Colors */
$black: #1d1f20;
$blue: #002975;
$blueriver: #2980b9;
$green: #a2ed56;
$yellow: #fddc32;
$white: #fafafa;


/*** EXTEND ***/
/* box-shadow */
%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}

.list-container {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;

  >li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;

    &::before,
    &::after {
      background: linear-gradient(135deg, $blue 0%, $blueriver 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: '';
      height: 3rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 3rem;
    }

    &::before {
      align-items: flex-end;
      @extend %boxshadow;
      content: counter(gradient-counter);
      color: #fff;
      display: flex;
      font: 900 1.5em/1 'Montserrat';
      justify-content: flex-end;
      padding: 0.125em 0.25em;
      z-index: 1;
    }

    @for $i from 1 through 5 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($blue, $i * 0.2) 0%, rgba($blueriver, $i * 0.2) 100%);
      }
    }

    @for $i from 6 through 10 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($blueriver, 1 - (($i - 5) * 0.2)) 0%, rgba($blue, 1 - (($i - 5) * 0.2)) 100%);
      }
    }

    +li {
      margin-top: 2rem;
    }
  }
}

.loader {
  position: relative;
  border-style: solid;
  box-sizing: border-box;
  border-width: 40px 60px 30px 60px;
  border-color: #3760C9 #96DDFC #96DDFC #36BBF7;
  animation: envFloating 1s ease-in-out infinite alternate;
}

.loader:after {
  content: "";
  position: absolute;
  right: 62px;
  top: -40px;
  height: 70px;
  width: 50px;
  background-image:
    linear-gradient(#3760C9 45px, transparent 0),
    linear-gradient(#3760C9 45px, transparent 0),
    linear-gradient(#3760C9 45px, transparent 0);
  background-repeat: no-repeat;
  background-size: 30px 4px;
  background-position: 0px 11px, 8px 35px, 0px 60px;
  animation: envDropping 0.75s linear infinite;
}

@keyframes envFloating {
  0% {
    transform: translate(-2px, -5px)
  }

  100% {
    transform: translate(0, 5px)
  }
}

@keyframes envDropping {
  0% {
    background-position: 100px 11px, 115px 35px, 105px 60px;
    opacity: 1;
  }

  50% {
    background-position: 0px 11px, 20px 35px, 5px 60px;
  }

  60% {
    background-position: -30px 11px, 0px 35px, -10px 60px;
  }

  75%,
  100% {
    background-position: -30px 11px, -30px 35px, -30px 60px;
    opacity: 0;
  }
}

.loader-tracking {
  position: relative;
  width: 130px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#0277bd, #0277bd),
    linear-gradient(#29b6f6, #4fc3f7), linear-gradient(#29b6f6, #4fc3f7);
  background-size: 80px 70px, 30px 50px, 30px 30px;
  background-position: 0 0, 80px 20px, 100px 40px;
}

.loader-tracking:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 12px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  box-sizing: content-box;
  border: 10px solid #000;
  box-shadow: 78px 0 0 -10px #fff, 78px 0 #000;
  animation: wheelSk 0.75s ease-in infinite alternate;
}

.loader-tracking:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  height: 70px;
  width: 70px;
  background-image: linear-gradient(#FF9200 45px, transparent 0),
    linear-gradient(#FF9200 45px, transparent 0),
    linear-gradient(#FF9200 45px, transparent 0);
  background-repeat: no-repeat;
  background-size: 30px 4px;
  background-position: 0px 11px, 8px 35px, 0px 60px;
  animation: lineDropping 0.75s linear infinite;
}

@keyframes wheelSk {

  0%,
  50%,
  100% {
    transform: translatey(0)
  }

  30%,
  90% {
    transform: translatey(-3px)
  }
}

@keyframes lineDropping {
  0% {
    background-position: 100px 11px, 115px 35px, 105px 60px;
    opacity: 1;
  }

  50% {
    background-position: 0px 11px, 20px 35px, 5px 60px
  }

  60% {
    background-position: -30px 11px, 0px 35px, -10px 60px
  }

  75%,
  100% {
    background-position: -30px 11px, -30px 35px, -30px 60px;
    opacity: 0;
  }
}

.loader-documentos {
  position: relative;
  width: 100px;
  height: 130px;
  background: rgb(247, 247, 247);
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.loader-documentos:before {
  content: '';
  position: absolute;
  width: 54px;
  height: 25px;
  left: 50%;
  top: 0;
  background-image:
    radial-gradient(ellipse at center, #0000 24%, #0041FF 25%, #0041FF 64%, #0000 65%),
    linear-gradient(to bottom, #0000 34%, #0041FF 35%);
  background-size: 12px 12px, 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  transform: translate(-50%, -65%);
  box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
}

.loader-documentos:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 66%;
  height: 60%;
  background: linear-gradient(to bottom, #0041FF 30%, #0000 31%);
  background-size: 100% 16px;
  animation: writeDown 2s ease-out infinite;
}

@keyframes writeDown {
  0% {
    height: 0%;
    opacity: 0;
  }

  20% {
    height: 0%;
    opacity: 1;
  }

  80% {
    height: 65%;
    opacity: 1;
  }

  100% {
    height: 65%;
    opacity: 0;
  }
}

.loader-cobranzas {
  transform: translateZ(1px);
}

.loader-cobranzas:after {
  content: '$';
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  background: #FFD700;
  color: #DAA520;
  border: 4px double;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .1);
  animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes coin-flip {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.loader-clientes {
  width: 48px;
  height: 24px;
  color: #0041FF;
  background: currentColor;
  border-radius: 50% 50% 0 0;
  position: relative;
  display: block;
  margin: 60px auto 0;
  box-sizing: border-box;
  animation: animloader 4s linear infinite;
}

.loader-clientes::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: currentColor;
  top: -34px;
  box-sizing: border-box;
  animation: animloader1 4s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px -1px 0 -2px, 0 0 0 -2px, 40px -1px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 40px -1px 0 -5px, -40px -1px 0 -5px;
  }

  60% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px, -40px -1px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px, -23px -29px 0 -5px;
  }

  100% {
    box-shadow: 40px -1px 0 -2px rgba(255, 255, 255, 0), -40px -1px 0 -2px rgba(255, 255, 255, 0), 23px -29px 0 -5px rgba(255, 255, 255, 0), -23px -29px 0 -5px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px 2px 0 -2px, 0 0 0 -2px, 40px 2px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 40px 2px 0 -5px, -40px 2px 0 -5px;
  }

  60% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px, -40px 2px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px, -23px -23px 0 -5px;
  }

  100% {
    box-shadow: 40px 2px 0 -2px rgba(255, 255, 255, 0), -40px 2px 0 -2px rgba(255, 255, 255, 0), 23px -23px 0 -5px rgba(255, 255, 255, 0), -23px -23px 0 -5px rgba(255, 255, 255, 0);
  }
}

.loader-dashboard {
  width: 200px;
  height: 140px;
  background: #15318E;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  perspective: 1000px;
}

.loader-dashboard:before {
  content: '';
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius: 8px;
  background: #f5f5f5 no-repeat;
  background-size: 60px 10px;
  background-image: linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0);

  background-position: 15px 30px, 15px 60px, 15px 90px,
    105px 30px, 105px 60px, 105px 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.loader-dashboard:after {
  content: '';
  position: absolute;
  width: calc(50% - 10px);
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius: 8px;
  background: #fff no-repeat;
  background-size: 60px 10px;
  background-image: linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0),
    linear-gradient(#FF9200 100px, transparent 0);
  background-position: 50% 30px, 50% 60px, 50% 90px;
  transform: rotateY(0deg);
  transform-origin: left center;
  animation: paging 1s linear infinite;
}


@keyframes paging {
  to {
    transform: rotateY(-180deg);
  }
}

/* Dashboard styles */
.position-loader {
  position: absolute;
  top: 40% !important;

  @media (min-width: 270px) and (max-width: 450px) {
    left: 25% !important;
  }

  ;

  @media (min-width: 451px) and (max-width: 600px) {
    left: 30% !important;
  }

  ;

  @media (min-width: 601px) and (max-width: 768px) {
    left: 35% !important;
  }

  ;

  @media (min-width: 769px) and (max-width: 1920px) {
    left: 50% !important;
  }

  ;
}

.position-loader-clientes {
  position: absolute;
  top: 40%;

  @media (min-width: 270px) and (max-width: 768px) {
    left: 45% !important;
  }

  ;

  @media (min-width: 769px) and (max-width: 1100px) {
    left: 60% !important;
  }

  ;

  @media (min-width: 1101px) {
    left: 55% !important;
  }

  ;
}

.position-loader-consultas {
  position: absolute;
  top: 40%;

  @media (min-width: 270px) and (max-width: 768px) {
    left: 40% !important;
  }

  ;

  @media (min-width: 769px) and (max-width: 1100px) {
    left: 60% !important;
  }

  ;

  @media (min-width: 1101px) {
    left: 52.5% !important;
  }

  ;
}

.position-loader-consultas-detalle {
  position: absolute;
  top: 40%;

  @media (min-width: 270px) and (max-width: 768px) {
    left: 40% !important;
  }

  ;

  @media (min-width: 769px) and (max-width: 1100px) {
    left: 60% !important;
  }

  ;

  @media (min-width: 1101px) {
    left: 52.5% !important;
  }

  ;
}

.position-loader-consultas-mobile {
  position: absolute;

  @media (min-width: 270px) and (max-width: 768px) {
    top: 60% !important;
    left: 12% !important;
  }

  ;

  @media (min-width: 768px) and (max-width: 1100px) {
    top: 80% !important;
    left: 12% !important;
  }

  ;

}

@mixin magic-border($width, $color, $duration, $direction) {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width:calc(100% + #{$width * 2});
    height:calc(100% + #{$width * 2});
    top:calc(#{$width}/-1);
    left:calc(#{$width}/-1);
    background: linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
    background-size: 100% $width;
    background-position: 0% 100%;
    background-repeat: no-repeat, no-repeat;
    transition: transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
    transform: scaleX(0) rotate(180deg * $direction);
    transition-delay: $duration, $duration, 0s;
  }

  &:hover {
    &:before {
      background-size: 200% $width;
      background-position: 50% 100%;
      transform: scaleX(1) rotate(180deg * $direction);
      transition-delay: 0s, $duration, $duration;
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 400px;

  button {
    text-decoration: none;
    color: #222;
    font-size: 16px;
    // padding:20px;
    background: #efefef;
    // text-transform:uppercase;
    text-align: center;
    @include magic-border(2px, blue, 0.1s, 0);
    // font-family:'Oswald';
    // margin:20px;
    flex-grow: 1;
    cursor: pointer;
  }
}

.spinner-login-text {
  color: #003fe6 !important;
}

.spinner-login {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #003fe6 !important;
  animation: spinner-a4dj62 1s infinite linear;
}

.spinner-login::before,
.spinner-login::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: spinner-a4dj62 2s infinite;
}

.spinner-login::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes spinner-a4dj62 {
  100% {
    transform: rotate(1turn);
  }
}

.navbar-toggler-icon {
  color: white !important
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.rs-theme-light {
  --rs-text-primary: #212529 !important;
}

body {
  height: 100%;
  margin: 0;
  color: #212529 !important;
  /* Apple-System,  */
  /* font-family: Helvetica, sans-serif !important;
   */
  font-family: "Satoshi", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

/*
==================================================================================
DISPLAYS
==================================================================================
*/
.d-sm-md-view {
  display: none !important;
}

.d-md-lg-view {
  display: none !important;
}

.d-slider {
  display: none !important;
}

.d-lg-xl-view {
  display: none !important;
}

.d-mb-block-table {
  display: block !important;
  display: table-cell !important;
}

.d-sm-block-table {
  display: block !important;
  display: table-cell !important;
}

/*
==================================================================================
FIN DISPLAYS
==================================================================================
*/

/*
==================================================================================
BACKGROUNDS Y RECOLORES
==================================================================================
*/

.bg-belmeny-gradient {
  background: rgb(37, 50, 109);
  background: linear-gradient(0deg, rgba(37, 50, 109, 1) 0%, rgba(0, 41, 117, 1) 50%, rgba(0, 41, 117, 1) 50%);
  /* background: #002975 !important; */
}

.bg-belmeny {
  background: #002975 !important;
}

.bg-belmeny3 {
  background: #0041ff !important;
}

.bg-megamenu {
  background: #063ccd !important;
}

.bg-header {
  background: #0C2090;
}

.bg-headerIngco {
  background: #000;
}

.bg-headerWadfow {
  background: #0033A1;
}

.bg-headerCorona {
  background: #0069B4;
}

.bg-headerFleximatic {
  background: #001242;
}

.bg-headerQuilosa {
  background: #001242;
}

.bg-headerImou {
  background: #F18D00;
}

.bg-dashboard {
  background: #fff9f7 !important;
}

.bg-lighten {
  background: #f8f9fa;
}

.bg-vert {
  background: #ef7226 !important;
}

.bg-vert2 {
  background: #ff9200 !important;
}

.bg-weekend {
  background: #00b4fc !important;
}

.bg-no-orders {
  background: #fbea80 !important;
}

.bg-items-vert {
  background: #0049b1 !important
}

.bg-vert {
  background: url('./assets/img/backgrounds/bckgrnd_vert.png') !important;
}

.bg-ingco {
  background: url('./assets/img/backgrounds/bckgrnd_ingco.png') !important;
}

.bg-wadfow {
  background: url('./assets/img/backgrounds/bckgrnd_wadfow.png') !important;
}

.bg-corona {
  background: url('./assets/img/backgrounds/bckgrnd_corona.png') !important;
}

.bg-imou {
  background: url('./assets/img/backgrounds/bckgrnd_imou.png') !important;
}

.bg-fleximatic {
  background: url('./assets/img/backgrounds/bckgrnd_fleximatic.png') !important;
}

.bg-quilosa {
  background: url('./assets/img/backgrounds/bckgrnd_quilosa.png') !important;
}

.bg-psh {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 100vh !important;
}

/* Add this CSS to make sure the content within the component doesn't affect the background */
.bg-vert .container-fluid,
.bg-ingco .container-fluid,
.bg-wadfow .container-fluid,
.bg-corona .container-fluid,
.bg-imou .container-fluid,
.bg-fleximatic .container-fluid,
.bg-quilosa .container-fluid {
  height: 100%;
}

.bg-banner {
  background: url('./assets/img/img-catalogo/menu/led-banner.jpg') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 10px
}

.bg-light-muted {
  background: #d8d8d8;
}

.text-belmeny {
  color: #002975 !important;
}

.text-belmeny-orange {
  color: #FE9101 !important
}

.text-ingco {
  color: #F6AF09 !important
}

.text-wadfow {
  color: #FF6B00 !important
}

.border-belmeny {
  border: 3px solid #002975 !important;
  border-radius: 10px !important;
}

.border-belmeny2 {
  border: 3px solid #0041ff !important;
  border-radius: 10px !important;
}

/*
==================================================================================
FIN BACKGROUNDS Y RECOLORES
==================================================================================
*/

/*
==================================================================================
FONTS
==================================================================================
*/

.satoshi-font {
  font-family: "Satoshi", sans-serif !important;
}

.apexcharts-text tspan {
  font-family: "Satoshi", sans-serif !important;
  /* font-size: 10px !important; */
}

/*
==================================================================================
FIN FONTS
==================================================================================
*/



/*
==================================================================================
KEYFRAMES
==================================================================================
*/

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

/*
==================================================================================
FIN KEYFRAMES
==================================================================================
*/

/*
==================================================================================
SPINNER Y LOADERS
==================================================================================
*/
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #000;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.loader,
.loader-tracking,
.loader-documentos,
.loader-cobranzas,
.loader-clientes,
.loader-dashboard {
  width: 48px;
  height: 48px;
  display: inline-block;
  /* border-top: 4px solid #FFF; */
  border-right: 4px solid transparent;
  box-sizing: border-box;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.spinner {
  width: 10% !important;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 40%;
  text-align: center;
  opacity: 1;
}

.login-load {
  /* opacity: 0.5; */
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* filter: brightness(50%); */
  z-index: 100000;
}

/* SVG loader made by: csozi | Website: www.csozi.hu*/

.loader-book {
  --background: linear-gradient(135deg, #23C4F8, #275EFE);
  --shadow: rgba(39, 94, 254, 0.28);
  --text: #6C7486;
  --page: rgba(255, 255, 255, 0.36);
  --page-fold: rgba(255, 255, 255, 0.52);
  --duration: 3s;
  width: 200px;
  height: 140px;
  position: relative;
}

.loader-book:before,
.loader-book:after {
  --r: -6deg;
  content: "";
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  box-shadow: 0 16px 12px var(--shadow);
  transform: rotate(var(--r));
}

.loader-book:before {
  left: 4px;
}

.loader-book:after {
  --r: 6deg;
  right: 4px;
}

.loader-book div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--shadow);
  background-image: var(--background);
}

.loader-book div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.loader-book div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--page);
  position: absolute;
  top: 10px;
  left: 10px;
  transform-origin: 100% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--duration) ease infinite;
  animation: var(--duration) ease infinite;
}

.loader-book div ul li:nth-child(2) {
  --c: var(--page-fold);
  -webkit-animation-name: page-2;
  animation-name: page-2;
}

.loader-book div ul li:nth-child(3) {
  --c: var(--page-fold);
  -webkit-animation-name: page-3;
  animation-name: page-3;
}

.loader-book div ul li:nth-child(4) {
  --c: var(--page-fold);
  -webkit-animation-name: page-4;
  animation-name: page-4;
}

.loader-book div ul li:nth-child(5) {
  --c: var(--page-fold);
  -webkit-animation-name: page-5;
  animation-name: page-5;
}

.loader-book div ul li svg {
  width: 90px;
  height: 120px;
  display: block;
}

.loader-book div ul li:first-child {
  --r: 0deg;
  --o: 1;
}

.loader-book div ul li:last-child {
  --o: 1;
}

.loader-book span {
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--text);
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  35%,
  100% {
    opacity: 0;
  }

  50%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }

  65%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  65%,
  100% {
    opacity: 0;
  }

  80%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
  }

  95%,
  100% {
    transform: rotateY(0deg);
  }
}

/*
==================================================================================
SPINNER Y LOADERS
==================================================================================
*/

/*
==================================================================================
DROP SHADOWS
==================================================================================
*/
.drop-shadow-sm {
  filter: drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.3));
}

.drop-shadow {
  box-shadow: 0px 12px 10px -2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 12px 10px -2px rgba(0, 0, 0, 0.5);
}

.drop-shadow-md {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.drop-shadow-lg {
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.drop-shadow-xl {
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
}

.drop-shadow-2xl {
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.drop-shadow-card {
  filter: drop-shadow(0px 0px 10px #0000001c);
}

.drop-shadow-video {
  filter: drop-shadow(0px 0px 15px #0000007c);
}

.drop-shadow-none {
  filter: drop-shadow(0 0 #0000);
}

.dropdown-menu.show {
  animation: fadeIn 300ms !important;
}

/*
==================================================================================
FIN DROP SHADOWS
==================================================================================
*/

/*
==================================================================================
BUTTONS
==================================================================================
*/

.btn {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.btn-hover {
  background-color: #031dd0 !important;
  color: #fff !important;
}

.btn-hover:hover {

  background-color: #FEFEFE !important;
  color: #002467 !important;
}

.btn-hover-details {
  background-color: #031dd0 !important;
  color: #fff !important;
}

.btn-hover-details:hover {

  background-color: #FEFEFE !important;
  color: #00379e !important;
}

.btn-hoverIngco {
  background-color: #fba10b !important;
  color: #fff !important;
}

.btn-hoverIngco:hover {

  background-color: #fff !important;
  color: #000 !important;
}

.btn-hoverWadfow {
  background-color: #FF6B00 !important;
  color: #fff !important;
}

.btn-hoverWadfow:hover {

  background-color: #FEFEFE !important;
  color: #000 !important;
}

.btn-hoverCorona {
  background-color: #0069B4 !important;
  color: #fff !important;
}

.btn-hoverCorona:hover {

  background-color: #FEFEFE !important;
  color: #000 !important;
}

.btn-hoverFleximatic {
  background-color: #c60f16 !important;
  color: #fff !important;
}

.btn-hoverFleximatic:hover {

  background-color: #eb1c24 !important;
  color: #fff !important;
}

.btn-hoverQuilosa {
  background-color: #c0151c !important;
  color: #fff !important;
}

.btn-hoverQuilosa:hover {

  background-color: #d61921 !important;
  color: #fff !important;
}

.btn-hoverImou {
  background-color: #efefef !important;
  color: #F18D00 !important;
}

.btn-hoverImou:hover {

  background-color: #FEFEFE !important;
  color: #F18D00 !important;
}

.btn-hover-login {
  background-color: #003fe6 !important;
  color: #fff !important;
}

.btn-hover-login:hover {

  background-color: #0066ff !important;
  color: #fff !important;
}

.fb-btn {
  cursor: pointer;
  color: rgba(0, 41, 117, 1);
  transition: 300ms;
}

.fb-btn:hover {
  transition: 300ms !important;
  color: #1778F2 !important
}

.tw-btn {
  cursor: pointer;
  color: rgba(0, 41, 117, 1);
  transition: 300ms;
}

.tw-btn:hover {
  transition: 300ms !important;
  color: #1DA1F2 !important
}

.ig-btn {
  cursor: pointer;
  color: rgba(0, 41, 117, 1);
  transition: 300ms;
}

.ig-btn:hover {
  transition: 300ms !important;
  color: #833AB4 !important
}

.moreInfoBtn {
  background-color: rgba(0, 41, 117, 1) !important;
  padding: 5px 10px !important;
  margin: 0;
}

.moreInfoBtn svg {
  color: #fff !important;
  fill: #fff !important;
}

/* BOTONES CON ANIMACIÓN X MARCA */

.buttonCategoryIngco {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #000;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryIngco::before {
  content: "";
  background: #1b1b1b;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonCategoryWadfow {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #f06400;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryWadfow::before {
  content: "";
  background: #FF6B00;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonCategoryCorona {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #0074c7;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryCorona::before {
  content: "";
  background: #0069B4;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonCategoryFleximatic {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #000e35;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryFleximatic::before {
  content: "";
  background: #001242;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonCategoryQuilosa {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #d61921;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryQuilosa::before {
  content: "";
  background: #c0151c;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonCategoryImou {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #df8200;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonCategoryImou::before {
  content: "";
  background: #F18D00;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonSettings span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.buttonSettings:hover span {
  color: ghostwhite;
}

.buttonSettings::before,
.buttonSettings::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.buttonSettings:hover::before {
  transform: translate3d(100%, 0, 0);
}

.buttonMonths {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #0043FF;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonMonths span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.buttonMonths:hover span {
  color: ghostwhite;
}

.buttonMonths::before,
.buttonMonths::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.buttonMonths::before {
  content: "";
  background: #0C2090;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonMonths:hover::before {
  transform: translate3d(100%, 0, 0);
}

.buttonBack {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #e53935;
  color: rgba(255, 255, 255, 0.5) !important;
}

.buttonBack span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.buttonBack:hover span {
  color: ghostwhite;
}

.buttonBack::before,
.buttonBack::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.buttonBack::before {
  content: "";
  background: #c62828;
  /* background: #e53935; */
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.buttonBack:hover::before {
  transform: translate3d(100%, 0, 0);
}

/*
==================================================================================
FIN BUTTONS
==================================================================================
*/

/* GENERAL CLASSES */

table {
  border-spacing: 500px !important;
  vertical-align: middle !important;
}

td {
  padding: 0px 6px !important;
}

ul li.client-info {
  font-size: 30px;
  color: rgba(0, 41, 117, 1);
}

li.client-info h4 {
  font-size: 22px;
  color: #000;
}

a {
  text-decoration: none !important;
}

.text-justify {
  text-align: justify !important;
}

.home {
  height: 100% !important;
  position: absolute;
}

.navbar-brand {
  margin-right: -10rem !important;
}


.footer {
  position: relative;
  width: 100%;
  bottom: 0 !important;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.alert-login {
  z-index: 1007;
}

.close-alert {
  cursor: pointer;
  transition: 300ms;
}

.close-alert:hover {
  transition: 300ms;
  color: red !important;
}



.searchbar {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px;
  border: none;
}

.searchbar:focus {
  outline: none;
}

.searchbar-btn {
  background-color: #363636;
  border: #363636;
  border-radius: 10px;
}

.searchbar-btn:hover {
  background-color: #414141;
}

.progress-bar-vertical {
  width: 20px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
  float: left;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.rs-picker-toggle-textbox {
  border-radius: 50rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.rs-picker-toggle:hover {
  border-radius: 50rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.rs-picker-toggle .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  border-radius: 50rem !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

/* Cabecera de Consultas */
.consultas-title {
  line-height: 0.5;
  text-align: center;
}

.consultas-title h4 {
  display: inline-block;
  position: relative;
  padding: 0 40px;
  font-weight: 400;
}

.consultas-title h4::before,
.consultas-title h4::after {
  content: "";
  position: absolute;
  border-bottom: solid 4px rgba(0, 41, 117, 1);
  border-radius: 360px;
  top: 50%;
  width: 630px;
}

.consultas-title h4::before {
  right: 100%;
  margin-right: 30px;
}

.consultas-title h4::after {
  left: 100%;
  margin-left: 30px;
}

/* Cabecera de Dashboard */
.dashboard-title {
  line-height: 0.5;
  text-align: center;
}

.dashboard-title h4 {
  display: inline-block;
  position: relative;
  padding: 0 50px;
  font-weight: 400;
}

.dashboard-title h4::before {
  right: 100%;
  margin-right: 30px;
}

.dashboard-title h4::after {
  left: 100%;
  margin-left: 30px;
}

.division {
  border: 2px solid rgba(0, 41, 117, 1);
  border-radius: 360px;
}

.rounded-card {
  border-radius: 20px !important;
}

.title-belmeny {
  font-size: 72px !important;
}

.subtitle-belmeny {
  font-size: 48px !important
}

.slider {
  position: relative !important;
  top: -5%;
  right: 5%;
  background-color: #0045B4;
}

.my-slider-progress {
  background: #ccc;
}

.my-slider-progress-bar {
  background: greenyellow;
  height: 2px;
  transition: width 400ms ease;
  width: 0;
}

.splide__arrow {
  background-color: rgba(0, 41, 117, 1) !important;
  padding: 25px !important;
  margin: 0;
  opacity: 1 !important;
}

.splide__arrows svg {
  color: #fff !important;
  fill: #fff !important;
}

.cover-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100% !important;
}

.noticeHeader {
  width: 100%;
  height: 50px;
  border: 5px solid rgba(0, 41, 117, 1) !important;
  background: rgba(0, 41, 117, 1) !important
}

.noticeBody {
  text-align: justify !important;
  width: 90% !important;
  margin: 15px auto;
}

.modal-close {
  cursor: pointer !important;
}

.badge-vendedor {
  background: #FF6700 !important;
}

.noticia-wrapper {
  height: 100%;
}

.collage-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.collage-col {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.collage-col img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Tracking styles */
.card {
  z-index: 0;
  background-color: #eceff1;
  padding-bottom: 20px;
  margin-top: 90px;
  margin-bottom: 40px;
  border-radius: 10px;
  width: 360px !important;
  margin-left: -15px;
}

.top {
  padding-top: 40px;
  padding-left: 13% !important;
  padding-right: 13% !important;
}

.progress-circle {
  background: #3498db;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

.connectbar-vertical {
  height: 900px !important;
  background-color: #3498db;
  position: absolute;
  top: 10%;
  z-index: -1;
  left: 45.5%;
  width: 30px !important;
}


.hiddenRow {
  padding: 0 !important;
}

.message {
  transition: 300ms all;
}

.message:hover {
  animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transform: translateX(10px);
  transition: 300ms all;
}

.shadow-drop-center {
  animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.start-95 {
  left: 95% !important
}

.custom-file {
  opacity: 0;
  position: absolute;
  z-index: -1 !important;
}

.Foto1st {
  cursor: pointer;
  border: 2px dashed darkblue;
  border-radius: 10px;
  padding: 0;
  margin: 0;
}

.Foto1st>img {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.upload-title {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.download-apk {
  transition: 300ms all;
}

.download-apk:hover {
  transition: 300ms all;
  background: rgb(0, 47, 134) !important;
}

.css-dip3t8 {
  background: linear-gradient(180deg, rgba(37, 50, 109, 1) 0%, rgba(0, 41, 117, 1) 50%, rgba(0, 41, 117, 1) 75%) !important;
}

.css-mf2zkt {
  background: rgba(0, 41, 117, 1) !important;
  transition: 300ms all;
}

.css-1tood33:hover {
  background: rgba(37, 50, 109, 1) !important;
  transition: 300ms all;
}

.css-12w9als {
  color: white !important;
}

.css-ylosnm,
.css-mf2zkt {
  padding-left: 0px !important;
  transition: 300ms !important;
  background: rgba(0, 41, 117, 1) !important;
}

.css-ylonsm>span {
  color: white !important;
}

.css-11esvgb:hover,
.css-64r2nk:hover,
.css-ymm6r7-MenuButtonRef:hover,
.css-fayd5x-MenuButtonRef:hover,
.css-13fpwnb:hover,
.css-ylonsm:hover,
.css-mf2zkt:hover {
  transition: 200ms !important;
  background-color: var(--rs-text-link-hover) !important;
}

.css-ylonsm:hover>span>a,
.css-12w9als:hover>a {
  text-decoration: none !important;
}

.css-il0j5r-MenuButtonRef {
  padding-left: 30px !important;
}

.css-il0j5r-MenuButtonRef:hover {
  padding-left: 35px !important;
  transition: 200ms !important;
  background-color: var(--rs-text-link-hover) !important;
}

.collapse-menu {
  cursor: pointer !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-36 {
  font-size: 36px !important;
}

.btn-belmeny {
  background: rgba(0, 41, 117, 1) !important;
}

.btn-belmeny:hover {
  background: rgb(0, 61, 175) !important;
}

.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 5px !important;
  min-height: 40px !important;
  min-width: 40px !important;
  border-radius: 20px !important;
  text-align: center;
  line-height: 25px;
  color: #007bff;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background: rgb(0, 61, 175) !important;
  border: 1px solid rgb(0, 61, 175);
  color: #fff;
}

.page-item.active .page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.rs-anim-fade,
.rs-anim-in,
.placement-bottom-start,
.rs-picker-daterange-menu,
.rs-picker-menu {
  z-index: 9000 !important;
}

.w-80 {
  width: 80% !important;
}

#dropdown-button-drop-down {
  width: 100% !important;
}

.scroll {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #e53935;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.portada {
  position: relative;
}

.portada img {
  width: 100%;
  /* Assuming you want the image to take up 100% of the container */
  height: auto;
  /* Maintain aspect ratio */
  transition: 300ms all ease-in-out;
}

.portada:hover {
  transform: scale(1.02);
  transition: 300ms all ease-in-out !important;
}

.cintillo {
  position: relative;
  transition: 200ms;
  border-radius: 10px;
  cursor: pointer;
}

.cintillo::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.cintillo:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

.cintillo-wrapper {
  position: relative;
  width: 100% !important;
}

.cintillo-base {
  width: 100% !important;
  border-radius: 5px;
}

.cintillo-vert {
  position: absolute;
  /* width: 75% !important; */
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms ease-out;
  border-radius: 5px;
}

.cintillo-vert:hover {
  opacity: 1;
  cursor: pointer;
  border-radius: 5px;
}

.productos-wrapper {
  position: relative;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #000000;
}

.image2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px solid #000000;
}

.cintillo-vert-2 {
  width: 100% !important;
  height: 240px !important;
  border-radius: 5px;
  transition: 300ms ease-out;
  background: url('../src/assets/img/img-catalogo/cintillos/cintillo-vert-base.png');
}

.cintillo-vert-2:hover {
  width: 100% !important;
  height: 240px !important;
  border-radius: 5px;
  transition: 300ms ease-out;
  background: url('../src/assets/img/img-catalogo/cintillos/cintillo-vert-logo.png');
}

.border-bottom-left-right {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* MEGA DROPDOWN MENU 2 */

ul>li {
  list-style-type: none;
}

.header .menu>ul>li .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #ffffff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
}

@media(min-width: 992px) {
  .header .menu>ul>li.menu-item-has-children:hover .sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.header .menu>ul>li .sub-menu>ul>li {
  line-height: 1;
}

.header .menu>ul>li .sub-menu>ul>li>a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li {
  line-height: 1;
  display: block;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>button {
  padding: 10px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  background: transparent;
  transition: color 0.3s ease;
  /* transition: 0.3s all ease-in-out !important; */
}

.header .menu>ul>li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.header .menu>ul>li .sub-menu.mega-menu-column-4 {
  max-width: 1500px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item {
  /* flex: 0 0 25%; */
  padding: 0 15px;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item .title {
  font-size: 16px;
  color: #0049b1;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
}

.title-vert {
  color: #0049b1 !important;
}

.title-ingco {
  color: #fba10b !important;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center .title {
  text-align: center;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item img {
  /* max-width: 100%;
  width: 100%; */
  vertical-align: middle;
  margin-top: 10px;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>button:hover,
.header .menu>ul>li .sub-menu>ul>li>button:hover,
.header .item-right button:hover,
.header .menu>ul>li:hover>button {
  color: #0049b1;
}

.title-hv {
  transition: 0.3s all ease-in-out !important;
}

.title-hv:hover {
  color: #003d92 !important;
  transition: 0.3s all ease-in-out !important;
}

.robo-font {
  font-family: 'Roboto', sans-serif;
}

.fw-900 {
  font-weight: 900 !important;
}

@media(max-width: 991px) {

  .header .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header .item-left,
  .header .item-right {
    flex: 0 0 auto;
  }

  .v-center {
    justify-content: space-between;
  }

  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #333333;
    width: 24px;
    position: relative;
  }

  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
  }

  .header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header .item-right {
    align-items: center;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .header .menu.active {
    transform: translate(0%);
  }

  .header .menu>ul>li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .header .menu>ul>li>a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header .menu>ul>li>a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }

  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }

  .header .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }

  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header .menu>ul>li .sub-menu.mega-menu,
  .header .menu>ul>li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
  }

  .header .menu>ul>li .sub-menu.active {
    display: block;
  }

  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item img {
    margin-top: 0;
  }

  .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center .title {
    margin-bottom: 20px;
  }

  .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center:last-child .title {
    margin-bottom: 0px;
  }

  .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item {
    flex: 0 0 100%;
    padding: 0px;
  }

  .header .menu>ul>li .sub-menu>ul>li>a,
  .header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>a {
    display: block;
  }

  .header .menu>ul>li .sub-menu.mega-menu>.list-item>ul {
    margin-bottom: 15px;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

.pointer {
  cursor: pointer !important;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/*
==================================================================================
MEDIA QUERIES
==================================================================================
*/

/* LOGIN */
/* Extra small Devices 320px and 576px */
@media (min-width: 320px) and (max-width: 576px) {
  .login-form {
    position: fixed;
    width: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

/* Small Devices (landscape phones, 576px and 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .login-form {
    position: fixed;
    width: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

/* Medium devices (tablets, 768px and 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  .login-form {
    position: fixed;
    width: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

/* Large devices (desktops, 992px and 1399px) */
@media (min-width: 992px) and (max-width: 1399px) {
  .login-form {
    position: fixed;
    width: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

/* X-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .login-form {
    position: fixed;
    width: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

/* LOGIN */

/* Tables for cellphone and desktop */
/* Cellphone */
@media (max-width: 768px) {
  .d-none-table {
    display: none !important;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .d-md-block-table {
    display: table-cell !important;
  }
}

/* Tables for cellphone and desktop */

/* Dashboard titles */
@media (min-width: 768px) {

  .dashboard-title h4::before,
  .dashboard-title h4::after,
  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 0%;
  }
}

@media (min-width: 1100px) {

  .dashboard-title h4::before,
  .dashboard-title h4::after,
  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 40%;
  }
}

@media (min-width: 1430px) {

  .dashboard-title h4::before,
  .dashboard-title h4::after,
  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 65%;
  }
}

@media (min-width: 1630px) {

  .dashboard-title h4::before,
  .dashboard-title h4::after,
  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 83%;
  }
}

@media (min-width: 1800px) {

  .dashboard-title h4::before,
  .dashboard-title h4::after,
  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 100%;
  }
}

/* Dashboard titles */

/* Consultas titles */
@media (max-width: 480px) {

  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 10px !important;
  }

  .rs-btn {
    width: 100% !important;
  }
}

@media (min-width: 481px) and (max-width: 575px) {

  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 40px !important;
  }

  .rs-btn {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 150px !important;
  }

  .rs-btn {
    width: 100% !important;
  }
}

/* @media (min-width: 1198.98px) {

  .consultas-title h4::before,
  .consultas-title h4::after {
    content: "";
    position: absolute;
    border-bottom: solid 4px rgba(0, 41, 117, 1);
    border-radius: 360px;
    top: 50%;
    width: 530px !important;
  }
} */

/* Consultas titles */

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .collage-col {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .collage-col {
    flex: 100%;
    max-width: 100%;
  }
}

/* Displays */
@media (min-width: 768px) and (max-width: 1099px) {
  .d-sm-md-view {
    display: block !important;
  }

  .d-md-lg-view {
    display: none !important;
  }

  .d-slider {
    display: none !important;
  }

  .d-lg-xl-view {
    display: none !important;
  }

  /* Tracking styles */
  .card {
    z-index: 0;
    background-color: #eceff1;
    padding-bottom: 20px;
    margin-top: 90px;
    margin-bottom: 40px;
    border-radius: 10px;
    width: 100% !important;
  }

  .connectbar {
    height: 25px;
    background-color: #3498db;
    position: absolute;
    top: 38%;
    z-index: -1;
    left: 8.5%;
    width: 85% !important;
  }
}

@media (min-width: 1100px) {
  .d-sm-md-view {
    display: none !important;
  }

  .d-md-lg-view {
    display: block !important;
  }

  .d-slider {
    display: none !important;
  }

  .d-lg-xl-view {
    display: none !important;
  }

  /* Tracking styles */
  .card {
    z-index: 0;
    background-color: #eceff1;
    padding-bottom: 20px;
    margin-top: 90px;
    margin-bottom: 40px;
    border-radius: 10px;
    width: 100% !important;
  }

  .connectbar {
    height: 25px;
    background-color: #3498db;
    position: absolute;
    top: 38%;
    z-index: -1;
    left: 8.5%;
    width: 85% !important;
  }
}

@media (min-width: 1630px) {
  .d-md-lg-view {
    background-color: red;
    display: none !important;
  }

  .d-slider {
    display: block !important;
  }

  .d-lg-xl-view {
    display: block !important;
  }

  /* Tracking styles */
  .card {
    z-index: 0;
    background-color: #eceff1;
    padding-bottom: 20px;
    margin-top: 90px;
    margin-bottom: 40px;
    border-radius: 10px;
    width: 100% !important;
  }

  .connectbar {
    height: 25px;
    background-color: #3498db;
    position: absolute;
    top: 38%;
    z-index: -1;
    left: 7.8%;
    width: 82% !important;
  }
}

/* Displays */

/*
==================================================================================
FIN MEDIA QUERIES
==================================================================================
*/